@font-face {
    font-family: "mission-gothic";
    src: url("/assets/fonts/Mission Gothic Regular.otf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "mission-gothic";
    src: url("/assets/fonts/Mission Gothic Bold.otf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "HanleyPro";
    src: url("/assets/fonts/hanley_pro_italic-webfont.woff") format("truetype");
    font-weight: normal;
    font-style: normal;
}

$primary-font: mission-gothic,
    Helvetica,
    Arial,
    sans-serif;
.primary-font {
    font-family: $primary-font
}

$secondary-font: "HanleyPro", sans-serif;
.secondary-font {
    font-family: $secondary-font;
}

h1 {
    font-size: 1.75rem;
    font-weight: 700;
    letter-spacing: -.05rem;
    line-height: 2.125rem;
    border-bottom-style: solid;
    border-bottom-width: .188rem;
    border-bottom-color: #433390;
    width: fit-content;
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
}

h2 {
    font-size: 1.25rem;
    font-weight: 700;
    letter-spacing: -.05rem;
    line-height: 1.625rem;
    margin-bottom: .75rem;
}

.detail-text {
    color: #072a79;
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-bottom: .1875rem;
}