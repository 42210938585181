@import "colors.scss";

@mixin button-variant($color, $background, $border) {
    color: $color;
    background-color: $background;
    border-color: $border;

    &:focus,
    &.focus {
        color: $color;
        background-color: darken($background, 10%);
        border-color: darken($border, 25%);
    }

    &:hover {
        color: $color;
        background-color: darken($background, 10%);
        border-color: darken($border, 12%);
    }

    &:active,
    &.active,
    .open>&.dropdown-toggle {
        color: $color;
        background-color: darken($background, 10%);
        border-color: darken($border, 12%);

        &:hover,
        &:focus,
        &.focus {
            color: $color;
            background-color: darken($background, 17%);
            border-color: darken($border, 25%);
        }
    }

    &:active,
    &.active,
    .open>&.dropdown-toggle {
        background-image: none;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {

        &,
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: $background;
            border-color: $border;
        }
    }

    .badge {
        color: $background;
        background-color: $color;
    }
}

// Mixin to create a bootstrap button with custom colors
@mixin bootstrap-button($background) {
    $color: #fff;
    $border: 5%;

    @if (lightness($background) >=lightness(#aaa)) {
        $color: #333;
        $border: .2 * lightness($background);
    }

    @include button-variant($color, $background, darken($background, $border));
}

@include media-breakpoint-up(md) {
    .btn.btn-primary-alt-md {
        @include bootstrap-button($secondary_color);
    }
}

@include media-breakpoint-up(lg) {
    .btn.btn-primary-alt-lg {
        @include bootstrap-button($secondary_color);
    }
}

@include media-breakpoint-down(lg) {
    .btn.btn-primary-alt-lg {
        @include bootstrap-button($primary_color);
    }
}

@include media-breakpoint-down(md) {
    .btn.btn-primary-alt-md {
        @include bootstrap-button($primary_color);
    }
}

.btn.btn-primary {
    @include bootstrap-button($primary_color);
}

.btn.btn-secondary {
    @include bootstrap-button($secondary_color);
}