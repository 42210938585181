@import "assets/styles/bootstrap_overrides.scss";
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "assets/styles/colors.scss";
@import "assets/styles/buttons.scss";
@import "assets/styles/text.scss";

@include media-breakpoint-up(md) {
    .event-card {
        width: 100%;
    }
}

@include media-breakpoint-up(lg) {
    .event-card {
        width: 50%;
    }
}

.btn-primary-alt {
    background-color: transparent;
}

.error,
.required {
    color: red;
}

body {
    font-family: $primary-font;
    background-color: $secondary_color;
}

.hint-text {
    opacity: 50%;
    font-style: italic;
}