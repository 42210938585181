@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

$primary_color: #ffffff;
.primary-color {
    color: $primary_color;
}

$secondary_color: #f2f2f2;
.secondary-color {
    color: $secondary_color;
}

@include media-breakpoint-up(md) {
    .alternate-primary-md {
        color: $secondary_color;
    }
}

@include media-breakpoint-down(md) {
    .alternate-primary-md {
        color: $primary_color;
    }
}